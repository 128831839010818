import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class TecnicalMaterialService {
  baseUrl: string;
  constructor(private httpClient: HttpClient) { 
    this.httpClient.get('assets/config.json').subscribe((res: any) => {
      this.baseUrl = res.apiGateway.url;
    });
  }

  /**
   *
   *
   * @param {*} id
   * @returns {Observable<any>}
   * @memberof TecnicalMaterialService
   */
  getTecnicalMaterialDetail(id): Observable<any> {
    return this.httpClient.get(this.baseUrl + 'technicalMaterials/' + id);
}

}
