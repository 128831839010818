import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { PurchaseOrder } from 'src/models/purchase-order';
import { AppConfigService } from "./app-config.service";
/**
 *
 *
 * @export
 * @class PurchaseOrderService
 */
@Injectable({ providedIn: "root" })
export class PurchaseOrderService {
  baseUrl: string;

  constructor(
    private httpClient: HttpClient,
    appConfigService: AppConfigService
  ) {
    this.baseUrl = appConfigService.baseUrl;
  }

  /**
   *
   *
   * @param {*} page
   * @param {*} items
   * @param {*} filter
   * @param {*} orderField
   * @param {*} orderDirection
   * @returns {Observable<any>}
   * @memberof PurchaseOrderService
   */
  getAllPurchaseOrder(
    page,
    items,
    filter,
    orderField,
    orderDirection
  ): Observable<any> {
    let url =
      this.baseUrl +
      "purchase-orders/page/" +
      page +
      "/elements/" +
      items +
      "/orderField/" +
      orderField +
      "/orderDirection/" +
      orderDirection +
      "?";
    let propFilter = Object.keys(filter);
    for (let index = 0; index < propFilter.length; index++) {
      if (
        filter[propFilter[index]] !== null &&
        filter[propFilter[index]] !== undefined &&
        filter[propFilter[index]] !== ""
      )
        url = url.concat(
          propFilter[index] +
            "=" +
            encodeURIComponent(filter[propFilter[index]]) +
            "&"
        );
    }
    return this.httpClient.get(url);
  }

  /**
   *
   *
   * @param {*} id
   * @returns {Observable<any>}
   * @memberof PurchaseOrderService
   */
  getDetailPurchaseOrder(id): Observable<any> {
    return this.httpClient.get(this.baseUrl + "purchase-orders/" + id);
  }

  /**
   *
   *
   * @param {*} POId
   * @param {*} POLId
   * @returns {Observable<any>}
   * @memberof PurchaseOrderService
   */
  getDetailPurchaseOrderLine(POId, POLId): Observable<any> {
    return this.httpClient.get(
      this.baseUrl + "purchase-orders/" + POId + "/lines/" + POLId
    );
  }

  /**
   *
   *
   * @param {*} field
   * @param {*} value
   * @returns {Observable<any>}
   * @memberof PurchaseOrderService
   */
  getFilterLabels(field, value): Observable<any> {
    return this.httpClient.get(
      this.baseUrl + "purchase-orders/filters/" + field + "/" + value
    );
  }

  /**
   *
   *
   * @param {File} file
   * @returns {Observable<any>}
   * @memberof PurchaseOrderService
   */
  uploadPOLinesFile(file: File): Observable<any> {
    let body: FormData = new FormData();
    body.append("file", file, file["name"]);
    return this.httpClient.post(
      this.baseUrl + "purchase-order-lines/parsed",
      body
    );
  }

  validate(file: File): Observable<PurchaseOrder[]> {
    let body: FormData = new FormData();
    body.append("file", file, file["name"]);
    return this.httpClient.post<PurchaseOrder[]>(
      this.baseUrl + "importer/purchase-orders/validate",
      body
    );
  }

  load(purchaseOrders: PurchaseOrder[]): Observable<boolean> {
    return this.httpClient.post<boolean>(this.baseUrl + "importer/purchase-orders/load", purchaseOrders);
  }
}
