import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
/**
 *
 *
 * @export
 * @class PLValidationService
 */
@Injectable({ providedIn: 'root' })

export class PLValidationService {
  baseUrl: string;
  constructor(private httpClient: HttpClient) {
    this.httpClient.get('assets/config.json').subscribe((res: any) => {
      this.baseUrl = res.apiGateway.url;
    });
  }

  /**
   *
   *
   * @param {Array<File>} files
   * @returns {Observable<any>}
   * @memberof PLValidationService
   */
  validatePackingList(file: File, isStructure: boolean, unitaryWeightAsTotalWeight: boolean): Observable<any> {
    let body: FormData = new FormData();

    body.append("file", file, file['name']);
    body.append("isStructure", isStructure ? 'true': 'false');
    body.append("unitaryWeightAsTotalWeight", unitaryWeightAsTotalWeight ? 'true': 'false');

    return this.httpClient.post(this.baseUrl + 'tools/packing-lists/validator', body);
  }

  /**
   *
   *
   * @param {*} body
   * @returns {Observable<any>}
   * @memberof PLValidationService
   */
  downloadErrorFile(body): Observable<any> {
    return this.httpClient.post(this.baseUrl + 'tools/packing-lists/validator/validation-report', body);
  }

  /**
   *
   *
   * @param {*} body
   * @returns
   * @memberof PLValidationService
   */
  pushValidatePackingList(body) {
    return this.httpClient.post(this.baseUrl + 'packing-lists/validated', body);
  }

  /**
   *
   *
   * @param {Array<File>} files
   * @returns {Observable<any>}
   * @memberof PLValidationService
   */
  uploadPackingList(files: Array<File>): Observable<any> {
    let body: FormData = new FormData();

    for (let i = 0; i < files.length; i++) {
      body.append("files", files[i], files[i]['name']);
    }
    return this.httpClient.post(this.baseUrl + "tools/packing-lists/importer/excel", body);
  }
}
