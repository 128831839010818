import { Injectable, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Library } from 'src/models/library';
import { AppConfigService } from './app-config.service';

/**
 * Service that manages Libraries
 *
 * @export
 * @class LibraryService
 */
@Injectable({ providedIn: 'root' })
export class LibraryService {
    
    baseUrl: string;

    constructor(private httpClient: HttpClient, appConfigService: AppConfigService) {
        this.baseUrl = appConfigService.baseUrl;
     }
    /**
     * Returns a list of libraries filtered an paged
     *
     * @param {*} page
     * @param {*} items
     * @param {*} filter
     * @param {*} orderField
     * @param {*} orderDirection
     * @returns an observable
     * @memberof LibraryService
     */
    list(page: any, items: any, filter: any, orderField: any, orderDirection: any): Observable<any> {
        let url = this.baseUrl + 'toponomastics/page/' + page + '/elements/' + items + '/orderField/' + orderField + '/orderDirection/' + orderDirection + '?';
        let propFilter = Object.keys(filter);
        for (let index = 0; index < propFilter.length; index++) {

            if (filter[propFilter[index]] !== null && filter[propFilter[index]] !== undefined && filter[propFilter[index]] !== "")
                url = url.concat(propFilter[index] + '=' + encodeURIComponent(filter[propFilter[index]]) + '&');

        }
        return this.httpClient.get(url);
    }

    /**
     * Retrieves the library with the given id
     * @param id
     * @returns an Observable
     * @memberof LibraryService
     */
    get(id: number): Observable<any> {
        return this.httpClient.get(this.baseUrl + 'toponomastics/' + id);
    }

    /**
     * Uploads a file containing libraries
     * @param file
     * @returns an Observable
     * @memberof LibraryService
     */
    upload(file: File): Observable<any> {
        let body: FormData = new FormData();
        body.append("file", file, file['name']);
        return this.httpClient.post(this.baseUrl + 'toponomastics/import', body);
    }

    /**
     * Updates an existing library
     * @param library
     */
    update(library: Library): Observable<any> {
        return this.httpClient.put(this.baseUrl + 'toponomastics/' + library.id, library);
    }

    /**
     * Creates a new library
     * @param library {Library}
     * @returns Observable<any>
     */
    create(library: Library): Observable<any> {
        return this.httpClient.post(this.baseUrl + 'toponomastics/', library);
    }

    /**
     * Deletes resource with id
     * @param id resource id
     * @returns an Observable
     */
    delete(id: number): Observable<any> {
        return this.httpClient.delete(this.baseUrl + 'toponomastics/' + id);
    }

}
