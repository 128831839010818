import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VendorService {
    baseUrl: string;
    constructor(private httpClient: HttpClient) { 
        this.httpClient.get('assets/config.json').subscribe((res: any) => {
            this.baseUrl = res.apiGateway.url;
        });
    }

    /**
     *
     *
     * @param {*} name
     * @returns {Observable<any>}
     * @memberof VendorService
     */
    getVendorDetail(name): Observable<any> {
        return this.httpClient.get(this.baseUrl + 'vendors?name=' + encodeURIComponent(name));
    }
}
