import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ExportExcelService {
    baseUrl: string;
    constructor(private httpClient: HttpClient) { 
        this.httpClient.get('assets/config.json').subscribe((res: any) => {
            this.baseUrl = res.apiGateway.url;
        });
    }

    /**
     *
     *
     * @param {*} filter
     * @param {*} orderField
     * @param {*} orderDirection
     * @returns {Observable<any>}
     * @memberof ExportExcelService
     */
    getPackingListExcel(filter, orderField, orderDirection): Observable<any> {
        let url = this.baseUrl + 'tools/packing-lists/exporter' + '/orderField/' + orderField + '/orderDirection/' + orderDirection + '?';
        let propFilter = Object.keys(filter);
        for (let index = 0; index < propFilter.length; index++) {

            if (filter[propFilter[index]] !== null && filter[propFilter[index]] !== undefined && filter[propFilter[index]] !== "")
                url = url.concat(propFilter[index] + '=' + encodeURIComponent(filter[propFilter[index]]) + '&');

        }
        return this.httpClient.get(url);
    }

    /**
     *
     *
     * @param {*} filter
     * @param {*} orderField
     * @param {*} orderDirection
     * @returns {Observable<any>}
     * @memberof ExportExcelService
     */
    getPurchaseOrdersExcel(filter, orderField, orderDirection): Observable<any> {
        let url = this.baseUrl + 'tools/purchase-orders/exporter' + '/orderField/' + orderField + '/orderDirection/' + orderDirection + '?';
        let propFilter = Object.keys(filter);
        for (let index = 0; index < propFilter.length; index++) {

            if (filter[propFilter[index]] !== null && filter[propFilter[index]] !== undefined && filter[propFilter[index]] !== "")
                url = url.concat(propFilter[index] + '=' + encodeURIComponent(filter[propFilter[index]]) + '&');

        }
        return this.httpClient.get(url);
    }

    getIpsDetailExcel(id):  Observable<any>{
      return this.httpClient.get(this.baseUrl + 'ips/excel/' + id);
    }

    getIpsStructureDetailExcel(id):  Observable<any>{
        return this.httpClient.get(this.baseUrl + 'ips/structure/excel/' + id);
    }

    /**
     *
     *
     * @param {*} filter
     * @param {*} orderField
     * @param {*} orderDirection
     * @returns {Observable<any>}
     * @memberof ExportExcelService
     */
    getIpsExcel(filter, orderField, orderDirection): Observable<any> {
        let url = this.baseUrl + 'tools/ips/exporter' + '/orderField/' + orderField + '/orderDirection/' + orderDirection + '?';
        let propFilter = Object.keys(filter);
        for (let index = 0; index < propFilter.length; index++) {

            if (filter[propFilter[index]] !== null && filter[propFilter[index]] !== undefined && filter[propFilter[index]] !== "")
                url = url.concat(propFilter[index] + '=' + encodeURIComponent(filter[propFilter[index]]) + '&');

        }
        return this.httpClient.get(url);
    }

}
